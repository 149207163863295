import React from "react";
import { useParams } from "react-router-dom";
import { Hub } from "@aws-amplify/core";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react";

const Login = () => {
  const { loginType } = useParams();

  React.useEffect(() => {
    Hub.dispatch("UI Auth", {
      event: "AuthStateChange",
      message: loginType,
      data: null,
    });
  }, [loginType]);

  return (
    <>
      <AmplifyAuthenticator
        initialAuthState={loginType}
      >
        <AmplifySignIn
          headerText="Custom login message"
          slot="sign-in"
          formFields={[
            {
              type: "username",
              label: "Custom Username Label",
              placeholder: "Custom username placeholder",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              label: "Custom Password Label",
              placeholder: "Custom password placeholder",
              inputProps: { required: true, autocomplete: "new-password" },
            },
            {
              type: "phone_number",
              label: "Custom Phone Label",
              placeholder: "Custom phone placeholder",
            },
          ]}
        ></AmplifySignIn>
        <AmplifySignUp
          headerText="Custom Signup message"
          slot="sign-up"
        ></AmplifySignUp>
      </AmplifyAuthenticator>
    </>
  );
};

export default Login;
