/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:84ae7727-b55d-4e3b-b720-ec9f6617a322",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_B2uJopwPw",
    "aws_user_pools_web_client_id": "3eipn6gdlg21tgra2oeclv0u4d",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://hhjyptmwu5c5dhtcpi4y4q5pfi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-aa4chqwaazd4jfew7hadgmowc4",
    "aws_user_files_s3_bucket": "346074156192-app-mptes-portal210341-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
