import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            signup: 'Join now',
            signin: 'Sign in',
            menu: {
              item_1: 'Item 1',
              item_2: 'Item 2',
              item_3: 'Item 3',
              item_4: 'Item 4',
              item_5: 'Item 5',
              item_5_menu: {
                item_1: 'Sub Item 1',
                item_2: 'Sub Item 2',
                item_3: 'Sub Item 3'
              }
            }
          }
        }
      }
    }
  });

export default i18n;
