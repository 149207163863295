import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import "./components/i18n/i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

import Amplify from "aws-amplify";
import config from "./aws-exports";
import { BrowserRouter } from "react-router-dom";

Amplify.configure(config);



ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
