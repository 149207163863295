import React from 'react'

const Welcome = () => {
    return (
        <div>
            <h1>Welcome</h1>
            <p>who we are, what we do!!!</p>
        </div>
    )
}

export default Welcome
