import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

import { AmplifySignOut } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import { useTranslation } from "react-i18next";

const Header = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  const { t } = useTranslation();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      console.log(authData);
    });
  }, []);

  const authLinks =
    authState === AuthState.SignedIn && user ? (
      <>
        <NavDropdown title={user.attributes.email}>
          <NavDropdown.Item>{t("header.menu.item_1")}</NavDropdown.Item>
          <NavDropdown.Item>{t("header.menu.item_2")}</NavDropdown.Item>
          <NavDropdown.Item>{t("header.menu.item_3")}</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item>{t("header.menu.item_4")}</NavDropdown.Item>
          <NavDropdown title={t("header.menu.item_5")}>
            <NavDropdown.Item>{t("header.menu.item_5_menu.item_1")}</NavDropdown.Item>
            <NavDropdown.Item>{t("header.menu.item_5_menu.item_2")}</NavDropdown.Item>
            <NavDropdown.Item>{t("header.menu.item_5_menu.item_3")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown.Divider />
          <NavDropdown.Item>
            <AmplifySignOut variant="outline-primary" />
          </NavDropdown.Item>
        </NavDropdown>
      </>
    ) : (
      <>
        <Nav.Link as={NavLink} to="/login/signup">
          {t("header.signup")}
        </Nav.Link>
        <Button as={NavLink} to="/login/signin" size="sm">
          {t("header.signin")}
        </Button>
      </>
    );

  return (
    <header>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand as={NavLink} to="/">
            <img
              alt=""
              src="/solarize-logo.50x50.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Solarize
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {authLinks}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
