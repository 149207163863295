import React from "react";
import { Container } from "react-bootstrap";
import { Route } from "react-router-dom";
import Amplify from "aws-amplify";

import Header from "./public/layout/Header";
import Login from "./public/pages/Login";
import Welcome from "./public/pages/Welcome";

const App = (props) => {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
  return (
    <>
      <Header />
      <main>
        <Container>
          <Route path="/" exact>
            <Welcome />
          </Route>
          <Route path="/login/:loginType">
            <Login />
          </Route>
        </Container>
      </main>{" "}
    </>
  );
};
export default App;
